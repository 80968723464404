// Vue Modals
//
// These are currently in place in the My Work area
//
// Markup: modal.html
//
// Styleguide Components.Modals.vue

@import 'library/definitions';

html {
  overflow: auto;
}
body.vue-modal-active {
  overflow:hidden;
  #app-wrap {
    -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
    filter: blur(5px);
  }
}
.vue-modal {
  z-index: 2000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: none;
  opacity: 0;
  overflow-y: scroll;
  background: rgba(236,249,246,.9);

  &:before {
    content:"";
    width: 1px;
    float: left;
    height: calc(100% + 1px);
    margin-left: -1px;
  }

  &:after {
    content:"";
    width: 100%;
    clear: both;
  }

  @include natural-animation(opacity);

  &.vue-modal--visible {
    display: block;
    opacity: 1;
  }

  .vue-modal__backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(236,249,246,.6);
    z-index: 15;
  }

  .vue-modal__container {
    position: relative;
    margin: 5% auto;
    width: 100%;
    max-width: 860px;
    border: 0;
    margin-bottom: 50px;
    background: transparent;
    border-radius: 2px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 50;
  }
  .vue-modal__close {
    position: absolute;
    height: 50px;
    width: 50px;
    right: 5px;
    top: 0;
    vertical-align: middle;
    text-align: center;
    font-size: 1.5rem;
    color: #028E6D;
    cursor: pointer;
    z-index: 1050;
    svg {
      position: relative;
      top: 50%;
      cursor: pointer;
      transform: translate(0, -50%);
    }
  }
  .vue-modal__header {
    position: relative;
    padding: 1rem;
    background: $green;
    color: $white;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-topright: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    .vue-modal__close {
      top: 50%;
      transform: translate(0, -50%);
    }
    h1, h2, h3, h4, h4, h5, h6 {
      color: $white;
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin: 0;
    }
  }
  .vue-modal__tabs {
    position: relative;
    background: #AFD6CE;
    padding: 13px 5px 0 5px;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-topright: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    @include media($medium-screen-up) {
      padding: 13px 30px 0 30px;
    }
    & > div {
      display: flex;
      flex-direction: row;
    }
    a {
      display: flex;
      background: $white;
      color: $green;
      padding: .6rem 2rem .3rem 2rem;
      font-family: Basetica-Medium, sans-serif;
      font-size: 1.15rem;
      line-height: 1.5rem;
      margin-right: rem(6);
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      @include media($medium-screen-up) {
        margin-right: rem(12);
      }
      .tab-count {
        background: #AFD6CE;
        color: #fff;
        display: inline-block;
        padding: rem(4) rem(8) 0 rem(8);
        margin-left: .5em;
        font-size: rem(14);
        line-height: 1.4;
        border-radius: 3px;
        @include media($medium-screen-up) {
          font-size: 1em;
          margin-top: -2px;
          line-height: 1em;
          padding: rem(6) rem(6) 0 rem(6);
        }
      }
      &.active {
        background: $green;
        color: $white;
        z-index: 10;
        .tab-count {
          background: #028E6D;
        }
      }

      @media (max-width: $small-screen) {
        font-size: rem(13);
        padding: .3rem .6rem;
      }

    }
  }
  .vue-modal__wrap {
    overflow-x:hidden;
    overflow-y:hidden;
    background: #fff;
    border-radius: 3px;
    border-top-left-radius: 0;
  }
  .vue-modal__content {
    position: relative;
    background: #fff;
    z-index: 11;
    border-radius: 3px;
    border-top-left-radius: 0;
    .vue-modal__close {
      color: $gray-dark;
    }
  }
  .vue-modal__footer {
    padding: 1rem 0;
    .button, button {
      margin: 0;
      float: none;
    }
  }

  .step-pagination {
    background-color: #CDEAE4;
    border-radius: rem(2);
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(32);
    width: rem(32);

    .pagination__arrow {
        fill: $mouse-green;
    }
  }

  .vue-modal__pagination {
    display: flex;
    justify-content: space-between;
  }
}
.tab-content {
  .tab-content__header {
    background: $green;
    color: $white;
    h1, h2, h3, h4, h5, h6 {
      color: $white;
      position: relative;
      font-size: 1.5rem;
      border-bottom: 2px solid #58bfaa;
      padding: 0rem 0 1rem 0;
      margin-bottom: 1.25rem;
    }
    margin: -1.5rem -1.5rem 1rem -1.5rem;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
  }

  .tab-content__no-bottom-margin {
    margin-bottom: 0;
  }
}


